import { useState, useEffect } from "react";
import Footer from "./components/Footer";
import CountDown from "./pages/CountDown";
import Home from "./pages/Home";
import { formatTime } from "./assets/helpers";

function App(): JSX.Element {
   // Target date for the countdown (year, month, day, hour, minute, second)
   const targetDate = new Date(2024, 2, 31, 0, 0, 0); //  March 30th, 2024, 12:00:00 AM

   const initialTimeInSeconds = Math.floor((targetDate.getTime() - Date.now()) / 1000);
 
  const [time, setTime] = useState<number>(initialTimeInSeconds);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime((prevTime: number) => prevTime - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [time]);

  const data = formatTime(time);

  return (
    <div className="relative p-[20px] sm:p-[40px] md:p-[70px] xl:p-[100px] max-w-screen-2xl mx-auto">
      {time >= 0  ? <CountDown data={data} /> : <Home />}
      <Footer />
    </div>
  );
}

export default App;
