import { useEffect, useState } from "react";

import walletLogo from "../assets/picture/Wallet.svg";

import arrowUpRightLogo from "../assets/picture/ArrowUpRight.svg";
import frame from "../assets/picture/Frame24.png";
import arrow from "../assets/picture/arrow.png";
import caretDown from "../assets/picture/CaretDown.png";
import Divider from "../components/Divider";
import MarqueeComponent from "../components/MarqueeComponent";
import ConnectWalletDialog from "../components/Dialog";
import Logo from "../components/Logo";

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isWalletConnected, setIsWalletConnected] = useState<boolean>(false);
  const [mintedCount, setMintedCount] = useState<number>(0);
  const [maxMintCount] = useState<number>(2000);
  const [remainingTime, setRemainingTime] = useState<{
    hours: number;
    minutes: number;
    seconds: number;
  }>({ hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const mintingEndTime = new Date().getTime() + 2 * 60 * 60 * 1000;

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDifference = mintingEndTime - currentTime;

      const seconds = Math.max(
        Math.floor((timeDifference % (1000 * 60)) / 1000),
        0
      );
      const minutes = Math.max(
        Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)),
        0
      );
      const hours = Math.max(
        Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        0
      );

      setRemainingTime({ hours, minutes, seconds });

      const currentMintedCount = Math.min(
        Math.floor((maxMintCount * timeDifference) / (2 * 60 * 60 * 1000)),
        maxMintCount
      );
      setMintedCount(currentMintedCount);
    }, 1000);

    return () => clearInterval(interval);
  }, [maxMintCount]);

  const handleClose = () => {
    setOpen(false);
    document.body.classList.remove("dialog-open");
  };
  const handleWalletConnected = () => {
    setIsWalletConnected(!isWalletConnected);
  };

  const handleOpen = () => {
    setOpen(true);
    document.body.classList.add("dialog-open");
  };
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col lg:flex-row lg:items-center justify-between">
          <Logo />
          <div className="flex flex-row items-end gap-x-[20px]">
            <div className="flex flex-col items-center gap-y-[10px]">
              <span className="">Connect your wallet to join the Mint</span>
              {isWalletConnected ? (
                <div className="flex sm:items-center gap-3">
                  <div className="hidden sm:flex items-center justify-center rounded-full w-full bg-flame sm:h-[50px] gap-x-[10px] cursor-pointer px-3">
                    <span className="text-white">MINT NFT </span>
                    <img src={arrow} alt="" />
                  </div>
                  <div className="sm:static fixed bottom-10 z-40 left-0 w-full flex items-center justify-center">
                    <div className="bg-white flex flex-row items-center justify-center rounded-full max-w-[300px] h-[50px] gap-x-[10px] cursor-pointer border px-3 shadow-sm">
                      <div className="w-5 h-5 rounded-full">
                        <img
                          src={frame}
                          className="w-full h-full"
                          alt="profile"
                        />
                      </div>
                      <span className="">TJ9c96D...Jdpn8Jg</span>
                      <img src={caretDown} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="sm:static fixed bottom-10 z-40 left-0 w-full flex items-center justify-center">
                  <div
                    onClick={handleOpen}
                    className="flex flex-row items-center justify-center rounded-full bg-flame max-w-[300px] w-full h-[50px] gap-x-[10px] cursor-pointer"
                  >
                    <span className="text-white">Connect Wallet</span>
                    <img src={walletLogo} alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Divider />
        <MarqueeComponent />

        <div className="w-full flex flex-col items-center justify-center gap-y-[10px]">
          <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-end mt-8">
            <span className="text-[22px] md:text-[36px]">MINTED</span>
            <span className="text-[36px] sm:text-[64px] lg:text-[96px] lexend-500">
              {mintedCount}/{maxMintCount}
            </span>
          </div>
          <Divider classes="mt-4" />
          <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-end gap-y-[10px]">
            <span className="text-[22px] md:text-[36px]">
              MINT REMAINING TIME
            </span>
            <div className="flex flex-row gap-x-[10px]">
              <div className="flex flex-row items-end gap-x-[10px]">
                <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
                  {remainingTime.hours.toString().padStart(2, "0")}
                </span>
                <span className="text-[14px] leading-[10px]">HH</span>
              </div>
              <div className="flex flex-row items-end gap-x-[10px]">
                <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
                  {remainingTime.minutes.toString().padStart(2, "0")}
                </span>
                <span className="text-[14px] leading-[10px]">MM</span>
              </div>
              <div className="flex flex-row items-end gap-x-[10px]">
                <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
                  {remainingTime.seconds.toString().padStart(2, "0")}
                </span>
                <span className="text-[14px] leading-[10px]">SS</span>
              </div>
            </div>
          </div>
          <Divider classes="my-4" />
          <div className="w-full">
            <div className="sm:hidden flex items-center w-fit justify-center rounded-full bg-flame h-[50px] sm:h-0 gap-x-[10px] cursor-pointer px-8">
              <span className="text-white">MINT NFT </span>
              <img src={arrow} alt="" />
            </div>
          </div>
        </div>

        {open && (
          <div className="block md:hidden">
            <div className="flex flex-col items-center gap-y-[10px] mt-[50px]">
              <div className="flex flex-row items-center justify-center rounded-full bg-flame w-[200px] h-[50px] gap-x-[10px] cursor-pointer">
                <span className="text-white">Mint NFT</span>
                <img src={arrowUpRightLogo} alt="" />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* dialog part */}
      <ConnectWalletDialog
        open={open}
        handleClose={handleClose}
        handleWalletConnected={handleWalletConnected}
      />
    </>
  );
};

export default Home;
