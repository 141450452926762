import React from "react";
import closeLogo from "../assets/picture/X.svg";

interface ConnectWalletDialogProps {
  open: boolean;
  handleClose: () => void;
  handleWalletConnected: () => void;
}

const ConnectWalletDialog: React.FC<ConnectWalletDialogProps> = ({
  open,
  handleClose,
  handleWalletConnected,
}) => {
  if (!open) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-screen overflow-auto bg-[#403F3F7A] z-10">
      <div className="flex w-full h-full items-center justify-center">
        <div className="w-[90%] max-w-[800px] bg-white rounded-2xl flex flex-col gap-y-[50px] p-[50px]">
          <div className="flex flex-row justify-between items-center">
            <span className="text-[24px]">Please enter your name</span>
            <img
              onClick={handleClose}
              src={closeLogo}
              alt=""
              className="cursor-pointer"
            />
          </div>
          <div className="flex flex-col gap-y-[10px]">
            <span className="text-[14px] text-[#9A9898]">Name*</span>
            <input
              type="text"
              className="bg-[#E6E5E5] text-[16px] placeholder:text-[#9A9898] outline-none rounded-[12px] h-[48px] px-[18px]"
              placeholder="Enter your name"
            />
          </div>
          <div
            onClick={() => {
              handleWalletConnected();
              handleClose();
            }}
            className="flex flex-row items-center justify-center text-white text-[16px] bg-[#0D0C0C] h-[48px] rounded-full cursor-pointer"
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWalletDialog;
