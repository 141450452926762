import { TimeData } from "../assets/helpers/types";
import Logo from "../components/Logo";

interface CountDownProps {
  data: TimeData;
}

const CountDown: React.FC<CountDownProps> = ({ data }) => {
  const { days, hours, minutes, seconds } = data;


  return (
    <div className="flex flex-col">
    <div className="flex flex-col lg:flex-row lg:items-center justify-between">
      <Logo />
    </div>
    <div className="w-full h-[400px] flex flex-col items-center justify-center gap-y-[10px]">
      <span className="text-[22px] md:text-[36px]">
        PUBLIC MINT WILL START IN
      </span>
      <div className="flex flex-row gap-x-[10px]">
        <div className="flex flex-row items-end gap-x-[10px]">
          <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
            {days}
          </span>
          <span className="text-[14px] leading-[10px]">DD</span>
        </div>
        <div className="flex flex-row items-end gap-x-[10px]">
          <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
            {hours}
          </span>
          <span className="text-[14px] leading-[10px]">HH</span>
        </div>
        <div className="flex flex-row items-end gap-x-[10px]">
          <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
            {minutes}
          </span>
          <span className="text-[14px] leading-[10px]">MM</span>
        </div>
        <div className="flex flex-row items-end gap-x-[10px]">
          <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
            {seconds}
          </span>
          <span className="text-[14px] leading-[10px]">SS</span>
        </div>
      </div>
    </div>
  </div>
  );
};

export default CountDown;
